var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{staticStyle:{"height":"calc(100% - 55px)","overflow":"auto","padding-bottom":"53px"},attrs:{"title":"新增字典","maskClosable":false,"width":"650","placement":"right","closable":false,"visible":_vm.dictAddVisiable},on:{"close":_vm.onClose}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',_vm._b({attrs:{"label":"键"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['keyy',
                 {rules: [
                  { required: true, message: '不能为空'}
                ]}]),expression:"['keyy',\n                 {rules: [\n                  { required: true, message: '不能为空'}\n                ]}]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"值"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['valuee',
                 {rules: [
                  { required: true, message: '不能为空'},
                  { max: 20, message: '长度不能超过20个字符'}
                ]}]),expression:"['valuee',\n                 {rules: [\n                  { required: true, message: '不能为空'},\n                  { max: 20, message: '长度不能超过20个字符'}\n                ]}]"}]})],1),_c('a-form-item',_vm._b({attrs:{"label":"表名"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tableName',
                 {rules: [
                  { required: true, message: '不能为空'},
                  { max: 20, message: '长度不能超过20个字符'}
                ]}]),expression:"['tableName',\n                 {rules: [\n                  { required: true, message: '不能为空'},\n                  { max: 20, message: '长度不能超过20个字符'}\n                ]}]"}]})],1),_c('a-form-item',_vm._b({attrs:{"label":"字段"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fieldName',
                 {rules: [
                  { required: true, message: '不能为空'},
                  { max: 20, message: '长度不能超过20个字符'}
                ]}]),expression:"['fieldName',\n                 {rules: [\n                  { required: true, message: '不能为空'},\n                  { max: 20, message: '长度不能超过20个字符'}\n                ]}]"}]})],1),_c('a-form-item',_vm._b({attrs:{"label":"其他"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['otherKeyy',
                 {rules: [
                  { max: 20, message: '长度不能超过20个字符'}
                ]}]),expression:"['otherKeyy',\n                 {rules: [\n                  { max: 20, message: '长度不能超过20个字符'}\n                ]}]"}]})],1)],1),_c('div',{staticClass:"drawer-bootom-button"},[_c('a-button',{staticStyle:{"margin-right":".8rem"},on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }